@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:200,300,400,500,600,700,800&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

@import './material/angular-material';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';
@import './material/material-overrides';
@import "~@ng-select/ng-select/themes/default.theme.css";

// regular style toast
@import 'ngx-toastr/toastr';


// install the framework and custom global styles
@include nb-install() {

  @include angular-material();

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
  @include material-overrides();
}

;

.cursor-pointer {
  cursor: pointer;
}

.primary-btn {
  background-color: #F78731 !important;
  color: #ffffff !important;
}

.secondary-btn {
  background-color: #d5cfcfd9 !important;
}

.second-inline-field {
  margin-left: 10rem !important;
}

.userpic {
  border: 1px dashed #b8b8b8;
  width: 202px;
  height: 202px;
  position: relative;
  cursor: pointer;

  .userpic-wrapper {
    width: 198px;
    height: 198px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  i {
    display: none;
    position: absolute;
    font-size: 32px;
    background: #ffffff;
    cursor: pointer;
    color: #f7931e;
    top: -11px;
    right: -11px;
    height: 26px;
    border-radius: 50%;

    :before {
      line-height: 26px;
    }
  }

  a.change-userpic {
    display: none;
    width: 202px;
    background: rgba(0, 0, 0, 0.7);
    transition: all 200ms ease-in-out;
    color: #fefefe !important;
    text-decoration: none;
    position: absolute;
    bottom: -1px;
    left: -1px;
    line-height: 32px;
    text-align: center;
  }

  &:hover {
    i {
      display: block;
    }

    .change-userpic {
      display: block;
    }
  }
}

.errormessage {
  color: red;
  font-size: 14px !important;
  margin-top: 5px;
  margin-bottom: 10px;
  display: block;
}

.required,
.col-red {
  color: red !important;
}

.modal-close {
  font-size: 20px;
  cursor: pointer;
}

.table thead th {
  text-transform: none !important;
  font-size: 14px !important;
  font-weight: 800 !important;
}

.table thead th {
  background: #e8e8e8;
  font-weight: 500;
  vertical-align: middle;
}

.table>tbody>tr>td {
  line-height: 30px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  vertical-align: middle;
}

.per-page {
  width: 65px;
  margin-top: -2px;
  margin-left: 5px
}

@media only screen and (max-width: 700px) {
  .second-inline-field {
    margin-left: 0rem !important;
    margin-top: 1rem !important;
  }

  .table-responsive::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
  }

  .table-responsive::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: #F5F5F5;
  }

  .table-responsive::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #555555;
  }

  .pagination-class {
    display: block !important;
    padding: 15px 0px !important;
  }

  ::ng-deep .ngx-pagination {
    padding-left: 0px;
  }

  .pagination-class .item-count {
    text-align: center;
    margin-bottom: 10px;
  }

  .entries-page {
    justify-content: center;
    margin-bottom: 10px;
  }

}

.nb-theme-material-light .label {
  font-size: 0.9375rem !important;
}

.nb-theme-material-light .header-container .logo {
  font-size: 1.37rem !important;
}

.nb-theme-material-light nb-menu .menu-group,
.nb-theme-material-light nb-menu .menu-item a {
  font-size: 0.97rem !important;
}

.menu-item a svg {
  width: 20px;
}